import {
  fetchAvatars
} from '@/api'

const state = {
  avatars: [],
  form: {
    avatarId: null,
    profileName: null,
    isKid: 0
  }
}

const mutations = {
  SET_AVATARS (state, avatars) {
    state.avatars = avatars
  },

  SET_ACTIVE_AVATAR (state, avatarId) {
    state.form.avatarId = avatarId
  },

  SET_ACTIVE_NAME (state, profileName) {
    state.form.profileName = profileName
  },

  SET_ACTIVE_KID (state, isKid) {
    state.form.isKid = isKid
  },

  CLEAR_FORM_DATA (state) {
    state.form.avatarId = null
    state.form.profileName = null
    state.form.isKid = 0
  }
}

const actions = {
  FETCH_AVATARS ({ commit }) {
    return fetchAvatars()
      .then((res) => res.data)
      .then((avatars) => commit('SET_AVATARS', avatars))
  }
}

const profile = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default profile
