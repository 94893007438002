<template>
  <Dialog
    :show="show"
    class="v-player--dialog--video-settings"
    @dialog:toggle="handleDialogToggle"
    ref="dialog"
  >
    <DialogMenu
      v-if="hasSources"
      :title="$t('player.labels.audios')"
      :items="sources"
      :value="selectedSource"
      @menu:changed="handleSourceChanged"
    />

    <template
      v-else
    >
      <DialogMenu
        v-if="hasAudios"
        :title="$t('player.labels.audios')"
        :items="audios"
        :value="selectedAudio"
        @menu:changed="handleAudioChanged"
      />

      <DialogMenu
        v-if="hasSubtitles"
        :title="$t('player.labels.subtitles')"
        :items="subtitles"
        :value="selectedSubtitle"
        @menu:changed="handleSubtitleChanged"
      />
    </template>
  </Dialog>
</template>

<script>
import Dialog from './Dialog.vue'
import DialogMenu from './DialogMenu.vue'

import _find from 'lodash/find'
import _forEach from 'lodash/forEach'

export default {
  name: 'VideoSettingsDialog',

  props: {
    show: Boolean
  },

  model: {
    prop: 'show',
    event: 'dialog:toggle'
  },

  data () {
    return {
      audios: [],
      subtitles: [],
      sources: [],
      selectedAudio: null,
      selectedSubtitle: null,
      selectedSource: null
    }
  },

  computed: {
    hasAudios () {
      return this.audios.length > 1
    },

    hasSubtitles () {
      return this.subtitles.length > 1
    },

    hasSources () {
      return this.sources.length > 1
    }
  },

  components: {
    Dialog,
    DialogMenu
  },

  mounted () {
    const player_ = this.$parent

    player_.$on('player:playlistonload', this.handlePlaylistOnload)
    player_.$on('player:trackschanged', this.handleTracksChanged)
    player_.$on('player:sourcetrackschanged', this.handleSourceTracksChanged)
  },

  beforeDestroy () {
    const player_ = this.$parent

    player_.$off('player:playlistonload', this.handlePlaylistOnload)
    player_.$off('player:trackschanged', this.handleTracksChanged)
    player_.$off('player:sourcetrackschanged', this.handleSourceTracksChanged)
  },

  methods: {
    handlePlaylistOnload () {
      this.audios = this.subtitles = this.sources = []
      this.selectedAudio = this.selectedSubtitle = this.selectedSource = null
      this.updateButton()
    },

    handleTracksChanged () {
      this.updateAudioTracks()
      this.updateTextTracks()
      this.updateButton()
    },

    handleSourceTracksChanged () {
      this.updateSourceTracks()
      this.updateButton()
    },

    handleAudioChanged (audio) {
      if (this.selectedAudio !== audio) {
        this.selectedAudio = audio
        this.$parent.setAudioLanguage(audio)
      }
    },

    handleSubtitleChanged (subtitle) {
      if (this.selectedSubtitle !== subtitle) {
        this.selectedSubtitle = subtitle
        this.$parent.setTextLanguage(subtitle)
      }
    },

    handleSourceChanged (audio) {
      if (this.selectedSource !== audio) {
        this.selectedSource = audio
        this.$parent.setSourceLanguage(audio)
      }
    },

    handleDialogToggle (show) {
      const player_ = this.$parent

      this.$emit('dialog:toggle', show)

      player_.isDialogActive = show
      player_.$emit('player:dialogtoggle', show)
    },

    updateAudioTracks () {
      const player_ = this.$parent
      const tracks = player_.getAudioTracks()

      if (tracks.length <= 1) {
        return
      }

      var audios = []

      _forEach(tracks, (track) => {
        var hasAudio = !!_find(audios, { value: track.language })

        if (hasAudio) return
        if (track.active) this.selectedAudio = track.language

        audios.push({
          label: this.$t(`player.audios.${track.language}`),
          value: track.language
        })
      })

      this.audios = audios

      this.$nextTick(() => player_.$emit('player:audiotrackloaded'))
    },

    updateTextTracks () {
      const player_ = this.$parent
      const tracks = player_.getTextTracks()

      if (!tracks.length) {
        return
      }

      var subtitles = []

      subtitles.push({
        label: this.$t('player.subtitles.off'),
        value: 'off'
      })

      _forEach(tracks, (track) => {
        if (['subtitle', 'subtitles'].indexOf(track.kind) === -1) return

        var hasSubtitle = !!_find(subtitles, { value: track.language })

        if (hasSubtitle) return
        if (track.active) this.selectedSubtitle = track.language

        subtitles.push({
          label: this.$t(`player.subtitles.${track.language}`),
          value: track.language
        })
      })

      if (!this.selectedSubtitle) {
        this.selectedSubtitle = 'off'
      }

      this.subtitles = subtitles

      this.$nextTick(() => player_.$emit('player:texttrackloaded'))
    },

    updateSourceTracks () {
      const player_ = this.$parent
      const tracks = player_.getSourceTracks()

      if (tracks.length <= 1) {
        return
      }

      var sources = []

      _forEach(tracks, (track) => {
        var hasTrack = !!_find(sources, { value: track.language })

        if (hasTrack) return
        if (track.active) this.selectedSource = track.language

        sources.push({
          label: this.$t(`player.audios.${track.language}`),
          value: track.language
        })
      })

      this.sources = sources

      this.$nextTick(() => player_.$emit('player:sourcetrackloaded'))
    },

    updateButton () {
      const button = this.$parent.$refs.videoSettingsDialogButton
      const text = this.hasSources ? 'player.buttons.source_settings' : 'player.buttons.video_settings'

      button.enable(this.hasAudios || this.hasSubtitles || this.hasSources)
      button.setTextDisplay(text)
    },

    toggle (show) {
      this.$refs.dialog.toggle(show)
    }
  }
}
</script>
