<template>
  <div id="app" class="app">
    <div class="app--wrapper">
      <v-logo v-show="showLogo" />

      <v-navbar
        v-sn="snOptions.navbar"
        v-model="showNavbar"
        :disabled="!activeNavbar"
        @navbar:expand="handleNavbarExpand"
        @navbar:collapse="handleNavbarCollapse"
        ref="navbar"
      >
        <ul class="nav v-navbar--nav">
          <li
            v-if="isAuthenticated"
            class="nav-item nav-item--member"
          >
            <router-link
              v-sn-focusable
              class="nav-link"
              :to="{ name: 'profile-choose' }"
              @sn:willmove.native="handleNavbarMenuBeforeDeactive"
              @sn:deactive.native="handleNavbarMenuDeactive"
              @sn:active.native="handleNavbarMenuActive"
            >
              <span class="nav-link--icon">
                <span
                  v-if="user.avatarUrl"
                  class="nav-link--icon--img"
                >
                  <img :src="user.avatarUrl" :alt="user.name">
                </span>

                <font-awesome-icon v-else class="d-block mx-auto" icon="circle-user" />
              </span>
              <span class="nav-link--label">
                {{ user.name }}
              </span>
            </router-link>
          </li>

          <li class="nav-item nav-item--home">
            <a
              v-sn-focusable="{ auto: false }"
              :class="{
                'nav-link': true,
                'is-active': isHome
              }"
              href=""
              @sn:willmove="handleNavbarMenuBeforeDeactive"
              @sn:deactive="handleNavbarMenuDeactive"
              @sn:active="handleNavbarMenuActive"
              @click.prevent="handleNavigateHome"
            >
              <span class="nav-link--icon">
                <font-awesome-icon class="d-block mx-auto" icon="house" />
              </span>
              <span class="nav-link--label">
                {{ $t('menu.home') }}
              </span>
            </a>
          </li>

          <template v-for="(menu, key) in menus">
            <li
              v-if="!menu.auth || menu.auth === isAuthenticated"
              :key="key"
              :class="[
                'nav-item',
                `nav-item--${menu.path.name}`,
                menu.name === 'menu.inbox' && activeMessageUnread ? 'is-active' : ''
              ]"
            >
              <router-link
                v-sn-focusable
                class="nav-link"
                :to="menu.path"
                :replace="menu.replace && !isHome"
                @sn:willmove.native="handleNavbarMenuBeforeDeactive"
                @sn:deactive.native="handleNavbarMenuDeactive"
                @sn:active.native="handleNavbarMenuActive"
              >
                <span class="nav-link--icon">
                  <font-awesome-icon class="d-block mx-auto" :icon="menu.icon" />
                </span>
                <span class="nav-link--label">
                  {{ $t(menu.name) }}
                </span>
              </router-link>
            </li>
          </template>
        </ul>
      </v-navbar>

      <main class="main">
        <div
          v-if="showLottieLoading"
          class="app--splash-background"
        >
          <v-lottie-player
            src="/splash-background.json"
            @lottie:ended="handleSplashBackgroundEnded"
          />
        </div>

        <router-view
          v-else
          class="main--wrapper"
        />
      </main>
    </div>
  </div>
</template>

<script>
import VLogo from '@/components/VLogo.vue'
import VNavbar from '@/components/VNavbar'
import VLottiePlayer from '@/components/VLottiePlayer'

import { keyCodes } from '@/utils/helpers'

import * as Sentry from '@sentry/vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'App',

  data () {
    return {
      showLogo: false,
      showNavbar: false,
      showLottieLoading: true,
      activeNavbar: true,
      snOptions: {
        navbar: {
          section: 'navbar',
          options: {
            disabled: true,
            leaveFor: {
              up: '',
              left: '',
              down: ''
            }
          }
        },
      },
      menus: [
        // { name: 'menu.home', path: { name: 'home' }, icon: 'house' },
        { name: 'menu.movie', path: { name: 'movie' }, icon: 'film', replace: true },
        { name: 'menu.series', path: { name: 'series' }, icon: 'tv', replace: true },
        { name: 'menu.upcoming', path: { name: 'upcoming' }, icon: 'compass', replace: false },
        // { name: 'menu.sport', path: { name: 'livesport' }, icon: 'basketball' },
        { name: 'menu.live', path: { name: 'livetv' }, icon: 'video', replace: true },
        { name: 'menu.search', path: { name: 'search' }, icon: 'magnifying-glass', replace: true },
        { name: 'menu.favorite', path: { name: 'favorite' }, icon: 'star', auth: true, replace: true },
        { name: 'menu.inbox', path: { name: 'inbox' }, icon: 'bell', auth: true, replace: true },
        { name: 'menu.history', path: { name: 'history' }, icon: 'clock', auth: true, replace: true },
        { name: 'menu.settings', path: { name: 'settings' }, icon: 'gear', replace: false },
        { name: 'menu.exit', path: { name: 'exit' }, icon: 'power-off', replace: false }
      ]
    }
  },

  computed: {
    ...mapState([
      'activePlatformBack',
      'device'
    ]),

    ...mapState('user', ['activeMessageUnread']),

    ...mapGetters({
      isWebOSTV: 'device/isWebOSTV',
      isWebOSTV4AndLatest: 'device/isWebOSTV4AndLatest',
      isAuthenticated: 'auth/isAuthenticated',
      user: 'user/activeUser'
    }),

    isActiveNavbar () {
      return this.showNavbar && this.activeNavbar
    },

    isHome () {
      return this.$route.name === 'home'
    },

    isStartPage () {
      return window.history.length === 1
    }
  },

  watch: {
    isActiveNavbar (active) {
      const { section } = this.snOptions.navbar

      if (active) {
        this.$sn.enable(section)
      } else {
        this.$sn.disable(section)
      }
    },

    isAuthenticated (active) {
      const token = JSON.parse(localStorage.getItem('mnmaxToken'))

      const options = active
        ? { auth: { headers: { Authorization: `Bearer ${token.access_token}` } } }
        : { auth: {} }

      this.$pusher.setOptions(options)
    }
  },

  components: {
    VLogo,
    VNavbar,
    VLottiePlayer
  },

  created () {
    this.$sn.pause()
  },

  beforeMount () {
    this._initKeyNavigation()
  },

  beforeDestroy () {
    this._removeKeyNavigation()
  },

  methods: {
    _initKeyNavigation () {
      window.addEventListener('keydown', this.handleBackButtonKeydown, true)
      window.addEventListener('cursorStateChange', this.handlePlatformCursor)
    },

    _removeKeyNavigation () {
      window.removeEventListener('keydown', this.handleBackButtonKeydown, true)
      window.removeEventListener('cursorStateChange', this.handlePlatformCursor)
    },

    handlePlatformCursor (e) {
      this.$store.commit('SET_ACTIVE_PLATFORM_CURSOR', e.detail ? e.detail.visibility : false)
    },

    handleNavbarExpand () {
      const { section } = this.snOptions.navbar
      this.$sn.focus(section)
    },

    handleNavbarCollapse () {
      this.$sn.move('right')
    },

    handleNavbarMenuBeforeDeactive (e) {
      const { direction } = e.detail
      const navbar = this.$refs.navbar

      if (direction === 'right' && navbar.isActive) {
        e.preventDefault()
        navbar.expand(false)
      }
    },

    handleNavbarMenuActive () {
      const navbar = this.$refs.navbar

      if (!navbar.isActive) {
        navbar.expand(true)
      }
    },

    handleNavbarMenuDeactive (e) {
      const { native, nextSectionId } = e.detail
      const { section } = this.snOptions.navbar
      const navbar = this.$refs.navbar

      if (!native && navbar.isActive && section !== nextSectionId) {
        navbar.expand(false)
      }
    },

    handleBackButtonKeydown (e) {
      const keycode = e.keyCode || e.which

      if (keyCodes.back.indexOf(keycode) !== -1) {
        const navbar = this.$refs.navbar

        if (navbar.isActive) {
          navbar.expand(false)
          return
        }

        if (this.activePlatformBack) {
          if (this.isHome) {
            this.$router.push({ name: 'exit' })
          } else {
            if (this.isStartPage) {
              this.$router.replace({ name: 'home' })
            } else {
              this.$router.back()
            }
          }
        }

        this.$sn.resume()
      }
    },

    handleNavigateHome () {
      if (this.isHome) return

      if (this.isStartPage) {
        this.$router.replace({ name: 'home' })
      } else {
        this.$router.back()
      }
    },

    async handleSplashBackgroundEnded () {
      if (this.isWebOSTV) {
        await this.$store.dispatch('device/FETCH_DEVICE_INFOMATION')
          .then((r) => {
            const isSucceeded = r && r.returnValue === true

            if (isSucceeded) {
              Sentry.setContext({
                name: 'Web0S',
                version: r.sdkVersion
              })
            }
          })
          .catch((e) => {
            Sentry.captureException(new Error(`Failed to retrieve tv device information (code: ${e.errorCode})`))
          })

        const campaignPageName = 'campaign'

        if (!this.isAuthenticated && this.$route.name !== campaignPageName) {
          await this.$router.replace({ name: campaignPageName })
        }
      }

      this.showLottieLoading = false
      this.showLogo = true

      this.$store.commit('SET_ACTIVE_PLATFORM_BACK', true)
    }
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
